<template>
    <app-collapse accordion>
        <app-collapse-item class="accordion-custom" v-for="accordion in accordions" v-bind:key="accordion.label" :title="accordion.label">
            <field
                v-if="accordion.fields.length > 0"
                :fields="accordion.fields"
                :ref="'fieldComponentOf'+accordion.label.split(' ').join('')"
            />
            <multiple-input
                v-if="(typeof accordion.multipleInput !== 'undefined')"
                :fields="accordion.multipleInput"
                :data="accordion.multipleInputValue"
                :ref="'multipleinputComponentOf'+accordion.label.split(' ').join('')"
            />
        </app-collapse-item>
  </app-collapse>
</template>
<script>
/* eslint-disable */
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Field from '@/views/Admin/components/Field'
import MultipleInput from '@/views/Admin/components/MultipleInput'

export default {
    name: 'Accrodion',
    props: ['accordions','data'],
    components: {
        AppCollapse,
        AppCollapseItem,
        Field,
        MultipleInput,
    },
    methods: {
        handleState() {
            let validate = true
            let formData = {}
            this.accordions.map((value, key) => {
                let keyComponent = 'fieldComponentOf'+value.label.split(' ').join('')
                let check = this.$refs[keyComponent]
                if(typeof check !== 'undefined') check = check[0].handleState()

                if(!check) validate = false
                else Object.assign(formData, check)


                keyComponent = 'multipleinputComponentOf'+value.label.split(' ').join('')
                check = this.$refs[keyComponent]
                if(typeof check !== 'undefined') check = check[0].handleState()
                
                if(check === false) validate = false
                else Object.assign(formData, check)
            })
            return (validate) ? formData : validate
        },
    },
}
/* eslint-enable */
</script>
<style scoped>
    .accordion-custom {
        border: 1px solid rgb(175, 175, 175) !important;
        border-radius: 5px;
        margin-bottom: 10px;
    }
</style>
