<template>
  <div id="app">
    <vue-progress-bar />
    <ErrorHandler />
    <div>
      <b-row>
        <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0">
                {{ pageTitle }}
              </h2>
            </b-col>
          </b-row>
        </b-col>
        <b-col
        class="content-header-right text-md-right d-md-block mb-1"
        md="6"
        cols="12"
        >
          <action-top v-if="actionTop.length > 0" :actionTop="actionTop"/>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-card-code>
        <list-view v-if="routeActive === routing.list" ref="listView"/>
        <detail-view v-else-if="routeActive === routing.detail" ref="detailView" />
        <form-view v-else-if="routeActive === routing.form" ref="formView" />
        <write-medrec v-else-if="routeActive === routing.writeMedrec" ref="writeMedrec" />
        <global-invoice v-else-if="routeActive === routing.globalInvoice" ref="globalInvoice" />
        <global-cart v-else-if="routeActive === routing.globalCart" ref="globalCart" />
        <lab-test v-else-if="routeActive === routing.labTest" ref="labTest" />
        <role-menu v-else-if="routeActive === routing.role" ref="roleMenu" />
        <ticket v-else-if="routeActive === routing.ticket" ref="ticket" />
      </b-card-code>
    </div>
    <global-form :data="globalFormData" :show="globalFormShow" />
    <open-form :data="openFormData" :show="openFormShow" />
    <grid-form :data="gridFormData" :show="gridFormShow" />
    <vue-grid :data="vueGridData" :show="vueGridShow" />
    <general-report :data="generalReportData" :show="generalReportShow" />
  </div>
</template>

<script>
/* eslint-disable */
import VueProgressbar from 'vue-progressbar'
import ErrorHandler from '@/views/Admin/components/ErrorAlert.vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BRow, BCol } from 'bootstrap-vue'
import ActionTop from '@/views/Admin/components/ActionTop.vue'
import GlobalForm from '@/views/Admin/components/GlobalForm.vue'
import OpenForm from '@/views/Admin/components/OpenForm.vue'
import GridForm from '@/views/Admin/components/GridForm.vue'
import VueGrid from '@/views/Admin/components/VueGrid.vue'
import ListView from '@/views/Admin/view/ListView.vue'
import DetailView from '@/views/Admin/view/DetailView.vue'
import FormView from '@/views/Admin/view/FormView.vue'
import GeneralReport from '@/views/Admin/components/GeneralReport.vue'
import WriteMedrec from '@/views/Admin/view/WriteMedicalRecord.vue'
import GlobalInvoice from '@/views/Admin/view/GlobalInvoice.vue'
import GlobalCart from '@/views/Admin/view/GlobalCart.vue'
import LabTest from '@/views/Admin/view/LabTest.vue'
import RoleMenu from '@/views/Admin/view/Role.vue'
import Ticket from '@/views/Admin/view/Ticket.vue'

export default {
  components: {
    VueProgressbar,
    BRow,
    BCol,
    BCardCode,
    ActionTop,
    GlobalForm,
    OpenForm,
    GridForm,
    VueGrid,
    ListView,
    DetailView,
    FormView,
    ErrorHandler,
    GeneralReport,
    WriteMedrec,
    GlobalInvoice,
    GlobalCart,
    LabTest,
    RoleMenu,
    Ticket
  },
  data() {
    return {
      pageTitle: '',
      actionTop: [],
      routeActive: this.$route.name,
      routing: {
        list: 'list',
        detail: 'detail-view',
        form: 'form',
        writeMedrec: 'write-medrec',
        globalInvoice: 'global-invoice',
        globalCart: 'global-cart',
        labTest: 'labtest',
        role: 'role',
        ticket: 'ticket'
      },
      globalFormShow: false,
      globalFormData: {},
      openFormShow: false,
      openFormData: {},
      gridFormShow: false,
      gridFormData: {},
      vueGridShow: false,
      vueGridData: {},
      generalReportShow: false,
      generalReportData: {},
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  watch: {
    '$route': function(to, from) {
      if(from.name !== to.name) window.location = to.fullPath
      this.load()
    },
  },
  mounted() {
    this.$http.interceptors.response.use(
      response => {
        if (response.status === 200 || response.status === 201) {
          this.$Progress.finish()
          return Promise.resolve(response)
        } else {
          this.$Progress.fail()
          this.loaderState(false)
          this.errorHandler(error.response.data)
          return Promise.reject(response)
        }
      },
      error => {
        if (error.response.status) {
          this.$Progress.fail()
          this.errorHandler(error.response.data)
          this.loaderState(false)
          if(error.response.status == 404) window.location.href = '/error-404'

          return Promise.reject(error.response)
        }
      }
    )

    this.$http.interceptors.request.use(
      config => {
        this.$Progress.start()
        return config
      },
      error => {
        this.$Progress.fail()
        this.errorHandler(error.response.data)
        this.loaderState(false)
        return Promise.reject(error)
      }
    )
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.pageTitle = ''
      this.actionTop = []
      this.globalFormShow = false
      this.globalFormData = {}
      this.openFormShow = false
      this.openFormData = {}
      this.gridFormShow = false
      this.gridFormData = {}
      this.vueGridShow = false
      this.vueGridData = {}
      this.generalReportShow = false
      this.generalReportData = {}

      this.checkCredential()
    },

    checkCredential() {
      this.$http.get('credential')
      .then(res => {
        if(res.data != 'OK') this.errorAuth(res.data)
        else this.settingConfig()
        this.loaderState(false)
      })
      .catch(err => this.loaderState(false))
    },

    settingConfig(configApi = null) {
      const url = (configApi) ? configApi : this.getConfigApi()
      this.$http.get(url)
        .then(res => {
          this.pageTitle = res.data.PageTitle
          this.actionTop = res.data.ActionTopButton
          document.title = res.data.PageTitle
        })
    },

    getConfigApi() {
      let configApi
      if(this.routeActive == this.routing.list) configApi = `${this.$route.params.module}/${this.$route.params.submodule}/config`
      else if(this.routeActive == this.routing.detail) configApi = `${this.$route.params.module}/${this.$route.params.submodule}/detail/${this.$route.params.oid}/config`
      else if(this.routeActive == this.routing.report) configApi = `generator/report/${this.$route.params.module}/config/${this.$route.params.submodule}`
      else if(this.routeActive == this.routing.writeMedrec) configApi = `archive/medrec/write/config/${this.$route.params.consultation}`
      else if(this.routeActive == this.routing.globalInvoice) configApi = `${this.$route.params.module}/${this.$route.params.submodule}/invoice/config/${this.$route.params.oid}`
      else if(this.routeActive == this.routing.globalCart) configApi = `${this.$route.params.module}/${this.$route.params.submodule}/cart/config/${this.$route.params.oid}`
      else if(this.routeActive == this.routing.form) configApi = `${this.$route.params.module}/${this.$route.params.submodule}/form/config`
      else if(this.routeActive == this.routing.ticket) configApi = this.portalApi() + '/support/ticket/config'

      return configApi
    },

    // Start After Request
    afterRequest(data, param = null) {
      if(!Array.isArray(data)) data = [data]
      data.map((value, key) => {
        if (typeof value === 'object') {
          value.param = param
          this.action(value)
        } else if (value === 'init') this.afterRequestInit()
        else if (value == 'windowClose') window.close()
      })
    },

    afterRequestInit() {
      if(this.routeActive == this.routing.list) this.$refs.listView.loadData()
      else if(this.routeActive == this.routing.detail) this.$refs.detailView.loadData()
      else if(this.routeActive == this.routing.globalCart) this.$refs.globalCart.loadData()
    },
    // End After Request

    // Start Action
    action(data, param = undefined) {
      if (data.type === 'GlobalForm' || data.type === 'ReportViewer') this.showGlobalForm(data)
      else if (data.type === 'OpenForm') this.showOpenForm(data)
      else if (data.type === 'Confirmation') this.confirmation(data)
      else if (data.type === 'OpenLink') this.openLink(data, param)
      else if (data.type === 'ApiCall') this.apiCall(data)
      else if (data.type === 'GridForm') this.showGridForm(data)
      else if (data.type === 'VueGrid') this.showVueGrid(data)
      else if (data.type === 'GeneralReport') this.showGeneralReport(data.data)
      else if (data.type === 'ChangeRoute') this.changeRoute(data)
      else if (data.type === 'Print') this.printArea(data)
    },
    // End Action

    // Start Global Form
    showGlobalForm(data) {
      let dataform = {}
      let forms = data.form
      let modal = data
      if(typeof data.get !== 'undefined') {
        this.$http.get(data.get).then(res => {
          dataform.Oid = res.data.Oid
          forms.map((value,key) => {
            dataform[value.fieldToSave] = res.data[value.fieldToSave]
          })

          this.globalFormData = { form: forms, data: dataform, modal: modal }
          this.globalFormShow = true
        })
      } else {
        this.globalFormData = { form: forms, data: {}, modal: modal }
        this.globalFormShow = true
      }
    },

    closeGlobalForm() {
      this.globalFormShow = false
    },
    // End Global Form

    // Start Open Form
    showOpenForm(data) {
      let dataform = {}
      let forms = data.form
      let modal = data
      if(typeof data.get !== 'undefined') {
        this.$http.get(data.get).then(res => {
          dataform.Oid = res.data.Oid
          forms.map((value,key) => {
            dataform[value.fieldToSave] = res.data[value.fieldToSave]
          })

          this.openFormData = { form: forms, data: dataform, modal: modal }
          this.openFormShow = true
        })
      }
    },

    closeOpenForm() {
      this.openFormShow = false
    },
    // End Open Form

    // Start Open Link
    openLink(data, param = undefined) {
      if (typeof data.post !== 'undefined') {
        if(param !== undefined) {
          this.$http.post(data.post, param).then(res => {
            window.open(res.data,'_blank')
            if(typeof data.afterRequest !== 'undefined') this.afterRequest(data.afterRequest, res.data)
          })
        } else {
          this.$http.post(data.post).then(res => {
            window.open(res.data,'_blank')
            if(typeof data.afterRequest !== 'undefined') this.afterRequest(data.afterRequest, res.data)
          })
        }
      } else if (typeof data.get !== 'undefined') {
        if(param !== undefined) {
          this.$http.get(data.get, { params: param }).then(res => {
            window.open(res.data,'_blank')
            if(typeof data.afterRequest !== 'undefined') this.afterRequest(data.afterRequest, res.data)
          })
        } else {
          this.$http.get(data.get).then(res => {
            window.open(res.data,'_blank')
            if(typeof data.afterRequest !== 'undefined') this.afterRequest(data.afterRequest, res.data)
          })
        }
      } else if (typeof data.link !== 'undefined') {
        if(typeof data.newTab !== 'undefined') {
          if(data.newTab === true) window.open(data.link,'_blank')
          else window.location.href = data.link
        } else window.open(data.link,'_blank')
      }
    },
    // End Open Link

    // Start Change Route
    changeRoute(data) {
      let route = data.link
      if(route.charAt(0) === '/') route.substring(0)

      if(typeof data.newTab !== 'undefined' && data.newTab) window.open('/' + route, '_blank');
      else window.location.href = '/' + route
    },
    // End Change Route

    // Start Print Area
    printArea(data) {
      let printContents = document.getElementById('printable').innerHTML
      document.body.innerHTML = printContents
      window.print()
      this.$router.go(this.$router.currentRoute)
    },
    // End Print Area

    // Start Confirmation
    confirmation(data) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to take this action ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post(data.post)
          .then(res => {
            if(typeof data.afterRequest !== 'undefined') this.afterRequest(data.afterRequest, res.data)
          })
        }
      })
    },
    // End Confirmation
    
    // Start Api Call
    apiCall(data) {
      this.loaderState(true)
      setTimeout(() => {
        if(typeof data.param !== 'object') data.param = { data: data.param }

        if(typeof data.post !== 'undefined') {
          this.$http.post(data.post, data.param)
          .then(res => {
            this.loaderState(false)
            if(typeof data.afterRequest !== 'undefined') this.afterRequest(data.afterRequest)
          })
          .catch(err => {
            this.loaderState(false)
          })
        } else if(typeof data.get !== 'undefined') {
          this.$http.get(data.get, data.param)
          .then(res => {
            this.loaderState(false)
            if(typeof data.afterRequest !== 'undefined') this.afterRequest(data.afterRequest)
          })
          .catch(err => {
            this.loaderState(false)
          })
        }
      }, 1000)
    },
    // End Api Call

    // Start Grid Form
    showGridForm(data) {
      let modal = data
      if(typeof data.get !== 'undefined') {
        this.$http.get(data.get).then(res => {
          this.gridFormData = { data: res.data, modal: modal }
          this.gridFormShow = true
        })
      } else {
        this.gridFormData = { data: {}, modal: modal }
        this.gridFormShow = true
      }
    },

    closeGridForm() {
      this.gridFormShow = false
    },
    // End Grid Form

    // Start Vue Grid
    showVueGrid(data) {
      let modal = data
      if(typeof data.get !== 'undefined') {
        this.$http.get(data.get).then(res => {
          this.vueGridData = { data: res.data, modal: modal }
          this.vueGridShow = true
        })
      } else {
        this.vueGridData = { data: {}, modal: modal }
        this.vueGridShow = true
      }
    },

    closeGridForm() {
      this.vueGridShow = false
    },
    // End Vue Grid

    // Start General Report
    showGeneralReport(data) {
      this.generalReportData = data
      this.generalReportShow = true
    },

    closeGeneralReport() {
      this.generalReportShow = false
    },
    // End General Report

    // Start Functions
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    },
    // End Functios
  },

}
/* eslint-enable */
</script>
