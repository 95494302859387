<template>
    <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
    >
        <template #button-content>
        <b-button
            variant="primary"
            class="btn-icon"
        >
            Action
            <feather-icon icon="ChevronDownIcon" />
        </b-button>
        </template>
        <b-dropdown-item v-for="action in actionTop" v-bind:key="action.name" @click="clickHandler(action)">
            <feather-icon
                :icon="action.icon"
                size="16"
            />
            <span class="align-middle ml-50">{{ action.name }}</span>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
/* eslint-disable */
import { BDropdown, BDropdownItem, BButton } from 'bootstrap-vue'

export default {
    props: ['actionTop'],
    components: {
        BButton,
        BDropdown,
        BDropdownItem,
    },
    data() {
        return {
            
        }
    },
    methods: {
        clickHandler(action) {
            this.$parent.action(action)
        }
    },
}
/* eslint-enable */
</script>
