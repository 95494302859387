<template>
  <div>
    <!-- PRESEARCH -->
    <section class="presearch row">
      <div class="col-md-10 col-12 row">
        <div class="col-md-4 col-12">
          <field v-if="presearch.length > 0" :fields="presearch" ref="presearchComponent"/>
        </div>
      </div>
      <div class="col-md-2 col-12">
        <label></label>
        <button class="btn btn-secondary btn-block" @click="search()">SEARCH</button>
      </div>
    </section>

    <!-- DATA -->
    <section class="data mt-3">
      <app-collapse accordion>
        <app-collapse-item class="accordion-custom" v-for="module in modules" v-bind:key="module.Oid" :title="module.Name">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead class="thead-dark">
                <tr>
                  <th>Module</th>
                  <th>Create</th>
                  <th>Read</th>
                  <th>Update</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="submodule in module.Submodule" v-bind:key="submodule.Oid">
                  <td>{{ submodule.Name }}</td>
                  <td><input type="checkbox" class="form-control" v-model="formData[submodule.Oid + '_create']"></td>
                  <td><input type="checkbox" class="form-control" v-model="formData[submodule.Oid + '_read']"></td>
                  <td><input type="checkbox" class="form-control" v-model="formData[submodule.Oid + '_update']"></td>
                  <td><input type="checkbox" class="form-control" v-model="formData[submodule.Oid + '_delete']"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </app-collapse-item>
      </app-collapse>
      <div class="text-right mt-2" v-if="modules.length > 0">
        <button class="btn btn-primary" @click="submit()">SUBMIT</button>
      </div>
    </section>
  </div>
</template>
<script>
/* eslint-disable */
import Field from '@/views/Admin/components/Field.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Field,
    AppCollapse,
    AppCollapseItem,
  },

  data() {
    return {
      presearch: [],
      selectedRole: null,
      modules: [],
      formData: {},
    }
  },

  created() {
    this.load()
  },

  methods: {

    load() {
      this.loadConfig()
      this.loadPresearch()
      this.loadModules()
    },

    loadConfig() {
      this.$parent.$parent.settingConfig('administrator/rolemenu/config')
    },

    loadPresearch() {
      const field = {
        fieldToSave: "Role",
        type: "combosource",
        column: "12",
        label: "Role",
        placeholder: "Select Role for setting Permission",
        required: 1,
        numberFormat: false,
        store: "administrator/role/combo"
      }
      this.presearch.push(field)
    },

    loadModules() {
      if(this.selectedRole !== null) {
        this.loaderState(true)
        this.$http.get('administrator/rolemenu', { params: {Role: this.selectedRole} })
        .then(res => {
          this.modules = res.data.Modules
          this.modules.map((value, key) => {
            value.Submodule.map((val, ky) => {
              this.$set(this.formData, val.Oid + '_create', false)
              this.$set(this.formData, val.Oid + '_read', false)
              this.$set(this.formData, val.Oid + '_update', false)
              this.$set(this.formData, val.Oid + '_delete', false)
            })
          })

          this.loadData(res.data.Data)
          this.loaderState(false)
        }) 
        .catch(err => this.loaderState(false))
      }
    },

    loadData(data) {
      data.map((value,key) => {
        this.formData[value.Oid + '_create'] = (value.Create) ? true : false
        this.formData[value.Oid + '_read'] = (value.Read) ? true : false
        this.formData[value.Oid + '_update'] = (value.Update) ? true : false
        this.formData[value.Oid + '_delete'] = (value.Delete) ? true : false
      })
    },

    submit() {
      this.loaderState(true)
      const params = { Role: this.selectedRole, Data: this.formData }
      this.$http.post('administrator/rolemenu', params)
      .then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Role Saved successfully',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.loaderState(false)
      })
      .catch(err => this.loaderState(false))
    },

    search() {
      const validate = this.$refs.presearchComponent.handleState()
      if(validate !== false) {
        this.selectedRole = validate.Role
        this.loadModules()
      }
    },
  },
}
</script>
<style scoped>
  .accordion-custom {
    border: 1px solid rgb(175, 175, 175) !important;
    border-radius: 5px;
    margin-bottom: 10px;
  }
</style>
