<template>
  <div class="app">
    <b-modal
      @shown="load"
      v-model="show"
      @hidden="handleClose"
      hide-footer
      centered
      ref="generalReportModal"
      title="General Report"
      size="full"
    >
      <div class="header">
        <div class="container-fluid">
          <div class="row">
            <template>
              <div class="w-100">
                <b-button
                  v-for="action in multiButton"
                  v-bind:key="action.name"
                  variant="primary"
                  size="sm"
                  class="float-right ml-1"
                  @click="buttonHandler(action)"
                >
                <feather-icon :icon="action.icon" />
                  {{ action.name }}
                </b-button>
              </div>
            </template>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-md-6">
              <h1><b>{{ config.Title }}</b></h1>
              <h4>{{ config.Company }}</h4>
              <h5 v-html="config.Subtitle"></h5>
            </div>
            <div class="col-md-6">
              <b-img class="float-right" width="100px" :src="config.Logo"></b-img>
            </div>
          </div>
          <!-- <b-table striped hover :items="items" :fields="fields" head-variant="dark"></b-table> -->
          <table class="table b-table table-striped table-hover">
            <thead class="thead-dark">
              <tr>
                <th class="text-center" v-for="field in fields" v-bind:key="field.key">{{ field.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" v-bind:key="item">
                <td
                  v-for="field in fields"
                  v-bind:key="field.key"
                  :class="(typeof item.class !== 'undefined' ? item.class : '')"
                  v-html="(typeof item[field.key] !== 'undefined') ? item[field.key] : ''">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { BModal, BTable, BButton, BImg, } from 'bootstrap-vue'

export default {
  name: 'GeneralReport',
  props: ['data','show'],
  components: {
    BImg,
    BButton,
    BModal,
    BTable,
  },
  data() {
    return {
      groups: [],
      items: [],
      fields: [],
      config: [],
      multiButton: [],
    }
  },
  methods: {
    reset() {
      this.groups = []
      this.items = []
      this.fields = []
      this.config = []
      this.multiButton = []
    },

    load() {
      this.reset()
      this.settingConfig()
      this.setMultiButton()
      this.setHeader()
      this.loadData()
    },

    settingConfig() {
      this.config = this.data.Config
    },

    loadData() {
      const tmp = this.data.Data
      const nf = new Intl.NumberFormat()

      let items = []
      tmp.map((value, key) => {
        const cols = Object.keys(value)
        let row = {}
        cols.map((val, ky) => {
          if(!isNaN(value[val])) this.$set(row, val, nf.format(value[val]))
          else this.$set(row, val, value[val])
        })

        items.push(row)
      })
      
      this.items = items
    },

    setHeader() {
      this.data.Column.map((value, key) => {
        let col = {}
        if(typeof value.title === 'undefined') col.label = value.field
        else col.label = value.title
        col.key = value.field

        this.fields.push(col)
      })
    },

    setMultiButton() {
      this.multiButton = this.data.MultiButton
    },

    handleClose() {
      this.reset()

      this.$parent.generalReportShow = false
    },

    buttonHandler(action) {
    this.$parent.action(action)
    }
  },
}
/* eslint-enable */
</script>

<style>
.modal-full {
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.modal-full .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
.modal-full .table-secondary > td {
  color: #fff;
  background-color: #545454;
}
.bg-group {
  background-color: #919191;
}
</style>
