<template>
  <div class="app">
    <b-modal
      @shown="load"
      v-model="show"
      centered
      ref="vueGridModal"
      title="Vue Grid"
      size="xl"
      @ok="handleOk"
      @hidden="handleClose"
      ok-title="Submit"
    >
      <div class="header">
        <div class="container-fluid">
          <b-table striped :items="items" :fields="fields" responsive="sm">
            <template #cell(checkbox)="row">
              <b-form-checkbox @change="selectItem(row)"/>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import { BModal, BTable, BButton, BImg, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'VueGrid',
  props: ['data','show'],
  components: {
    BImg,
    BButton,
    BModal,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      items: [],
      fields: [],
      selectedItem: [],
    }
  },
  methods: {
    reset() {
      this.items = []
    },

    load() {
      this.reset()
      if(this.data.data.length > 0) this.setHeader()
      if(this.data.data.length > 0) this.loadData()
    },

    setHeader() {
      let cols = Object.keys(this.data.data[0])

      const col = []
      
      col.push({
        label : '#',
        key: 'checkbox'
      })

      cols.map((value,key) => {
        let headerLabel = value
        if(value.includes('Name') && value !== 'Name') {
          headerLabel = value.replace('Name','')
          cols.splice(cols.indexOf(headerLabel), 1)
        } 

        const tmp = {}
        if(value !== 'Oid') {
          tmp.label = headerLabel
          tmp.key = value
        }
        
        return col.push(tmp)
      })

      this.fields = col
    },

    loadData() {
      let index = 0
      this.data.data.map((value, key) => {
        let keys = Object.keys(value)
        keys.map((val, ky) => {
          if(this.$parent.isNumeric(value[val]) && val !== 'Code') this.data.data[index][val] = new Intl.NumberFormat().format(parseFloat(value[val]))
        })
        index++
      })

      this.items = this.data.data
    },

    handleClose() {
      this.reset()

      this.$parent.vueGridShow = false
    },

    selectItem(data) {
      if(this.selectedItem.includes(data.item.Oid)) {
        let index = this.selectedItem.indexOf(data.item.Oid)
        this.selectedItem.splice(index,1)
      } else {
        this.selectedItem.push(data.item.Oid)
      }
    },

    handleOk(e) {
      e.preventDefault()
      let validate = this.selectedItem.length
      if(validate > 0) this.handleSubmit()
    },

    handleSubmit() {
      let body = {
        data : this.selectedItem
      }

      this.$http.post(this.data.modal.post, body )
        .then(res => {
            if(typeof this.data.modal.afterRequest !== 'undefined') this.$parent.afterRequest(this.data.modal.afterRequest, res.data)
            this.handleClose()
        })
    },
  },
}
/* eslint-enable */
</script>

<style>
.modal-full {
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.modal-full .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
.modal-full .table-secondary > td {
  color: #fff;
  background-color: #545454;
}
</style>
