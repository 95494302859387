<template>
    <div>
        <form ref="form">
            <field v-if="form.length > 0" :fields="form" ref="fieldComponent"/>
            <div class="text-right" v-if="form.length > 0">
                <b-button
                    variant="primary"
                    @click="handleSubmit"
                >
                    {{ (type == 'ReportViewer') ? 'Show Report' : 'Submit' }}
                </b-button>
            </div>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
import { BButton } from 'bootstrap-vue'
import Field from '@/views/Admin/components/Field.vue'

export default {
    name: 'ReportForm',
    props: [],
    components: {
        BButton,
        Field,
    },
    watch: {
        '$route': function(to, from) {
            this.loaderState(true)
            this.load()
        },
    },
    data() {
        return {
            form: [],
            data: {},
            post: '',
            type: '',
            afterRequest: '',
            formData: {},
        }
    },
    created() {
        this.load()
    },
    methods: {
        reset() {
            this.form = [],
            this.data = {},
            this.post = ''
            this.type = ''
            this.afterRequest = ''
        },

        load() {
            this.reset()
            this.$http.get(`${this.$route.params.module}/${this.$route.params.submodule}/form`)
            .then(res => {
                if(typeof res.data.get !== 'undefined') this.get(res.data.get)

                this.form = res.data.form
                this.post = res.data.post
                this.type = res.data.type
                if(typeof res.data.afterRequest !== 'undefined') this.afterRequest(res.data.afterRequest)
                this.loaderState(false)
            })
        },

        handleSubmit(e) {
            e.preventDefault()
            let validate = this.$refs.fieldComponent.handleState()
            if(validate !== false) {
                this.formData = validate
                this.submit()
            }
        },

        submit() {
            let body
            if(this.$refs.fieldComponent.bodyType == 'formdata') body = new FormData()
            else body = {}

            this.form.map((value,key) => {
                if(value.type === 'file') {
                    body.append(value.fieldToSave, this.formData[value.fieldToSave], this.formData[value.fieldToSave].name)
                } else {
                    if(this.$refs.fieldComponent.bodyType == 'formdata') body.append(value.fieldToSave, this.formData[value.fieldToSave])
                    else body[value.fieldToSave] = this.formData[value.fieldToSave]
                }
            })

            this.loaderState(true)
            this.$http.post(this.post, body )
            .then(res => {
                if(this.type == 'Form') {
                    if(typeof this.afterRequest !== 'undefined') this.$parent.$parent.afterRequest(this.afterRequest, res.data)
                    this.loaderState(false)
                } else if (this.type == 'ReportViewer') {
                    let reportModal = {
                        type : 'GeneralReport',
                        data : res.data
                    }
                    this.$parent.$parent.action(reportModal)
                    this.loaderState(false)
                }
            })
            .catch(err => {
                this.loaderState(false)
            })
        }
    },
}
/* eslint-enable */
</script>
