<template>
  <b-modal
    @shown="loadForm"
    @hidden="handleClose"
    ok-only
    ok-title="Close"
    centered
    title="Grid Form"
    size="lg"
    no-close-on-backdrop
    v-model="show"
  >
    <b-table striped :items="rows" :fields="cols"></b-table>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { BTable } from 'bootstrap-vue'

export default {
  name: 'GridForm',
  props: ['data', 'show'],
  data() {
    return {
      cols: [],
      rows: [],
    }
  },
  components: {
      BTable,
  },
  methods: {
    reset() {
      this.cols = []
      this.rows = []
    },

    loadForm() {
        this.reset()
        Object.keys(this.data.data[0]).map((value, key) => {
            let tmp = {
                key: value,
                label: value
            }
            this.cols.push(tmp)
        })
        this.rows = this.data.data
    },

    handleClose() {
      this.reset()

      this.$parent.gridFormShow = false
    }
  },
}
/* eslint-enable */
</script>
