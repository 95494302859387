<template>
  <div>
    <b-tabs id="tabs">
      <b-tab v-for="tab in tabs" v-bind:key="tab.Oid" @click="clickHandler(tab)"
      >
        <template #title>
          <feather-icon :icon="tab.Icon" />
          <span>{{ tab.Name }}</span>
        </template>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
/* eslint-disable */
import { BTabs, BTab } from 'bootstrap-vue'

export default {
  name: 'Tabs',
  props: ['tabs','serverParams','loadData'],
  components: {
    BTabs,
    BTab,
  },
  watch: {
    tabs: function(){
      if(this.tabs) this.addParams(this.tabs[0])
    }
  },
  methods: {
    addParams(data) {
      this.serverParams[data.Param] = data.Oid
    },

    clickHandler(data) {
      this.serverParams[data.Param] = data.Oid
      this.loadData()
    }
  },
}
/* eslint-enable */
</script>
