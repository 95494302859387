<template>
    <b-modal
        @shown="loadForm"
        @ok="handleOk"
        @hidden="handleClose"
        ok-title="Submit"
        centered
        :title="(data.modal.type == 'GlobalForm') ? data.modal.name : 'Report Viewer'"
        :size="size"
        no-close-on-backdrop
        v-model="show"
    >
      <form ref="form">
          <field v-if="form.length > 0" :fields="form" :data="fieldData" ref="fieldComponent"/>
      </form>
    </b-modal>
</template>

<script>
/* eslint-disable */
import { BModal } from 'bootstrap-vue'
import Field from '@/views/Admin/components/Field.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    name: 'GlobalForm',
    props: ['data','show'],
    components: {
        BModal,
        Field,
    },
    data() {
        return {
            size: '',
            form: [],
            fieldData: {},
            formData: {},
        }
    },
    methods: {
        reset() {
            this.size = ''
            this.form = []
            this.fieldData = {}
            this.formData = {}
        },

        loadForm() {
            this.reset()

            if(typeof this.data.modal.size !== 'undefined') this.size = this.data.modal.size
            if(Object.keys(this.data.data).length > 0) this.fieldData = this.data.data
            if(this.data.modal.form.length > 0) this.form = this.data.modal.form
        },

        handleOk(e) {
            e.preventDefault()
            let validate = this.$refs.fieldComponent.handleState()
            if(validate !== false) {
                this.formData = validate
                this.handleSubmit()
            }
        },

        handleSubmit() {
            let body
            if(this.$refs.fieldComponent.bodyType == 'formdata') body = new FormData()
            else body = {}

            this.data.modal.form.map((value,key) => {
                if(value.type === 'file') {
                    body.append(value.fieldToSave, this.formData[value.fieldToSave], this.formData[value.fieldToSave].name)
                } else {
                    if(this.$refs.fieldComponent.bodyType == 'formdata') body.append(value.fieldToSave, this.formData[value.fieldToSave])
                    else body[value.fieldToSave] = this.formData[value.fieldToSave]
                }
            })

            this.loaderState(true)
            this.$http.post(this.data.modal.post, body )
                .then(res => {
                    if(this.data.modal.type == 'GlobalForm') {
                        if(typeof this.data.modal.afterRequest !== 'undefined') this.$parent.afterRequest(this.data.modal.afterRequest, res.data)
                        this.handleClose()
                        this.loaderState(false)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Data Saved successfully',
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        })
                    } else if (this.data.modal.type == 'ReportViewer') {
                        this.loaderState(false)
                        this.reportViewer(res.data)
                    }
                })
                .catch(err => {
                    this.loaderState(false)
                })
        },

        reportViewer(tmp) {
            let data = {
                type : 'GeneralReport',
                data : tmp
            }
            this.$parent.action(data)
            this.handleClose()
        },

        handleClose() {
            this.reset()

            this.$parent.globalFormShow = false
        }
    },
}
/* eslint-enable */
</script>
