<template>
  <div>
    <!-- HEADER -->
    <section class="header">
      <table style="width: 100%">
        <tr>
          <th width="260">Patient</th>
          <td>: {{ data.Patient }}</td>
        </tr>
        <tr>
          <th>Medical Personnel (primary)</th>
          <td v-html="': '+data.Staff"></td>
        </tr>
        <tr>
          <th>Date</th>
          <td>: {{ data.Date }}</td>
        </tr>
      </table>
    </section>
    <!-- END HEADER -->

    <!-- FORM INPUT SECTION -->
    <section class="form-input mt-2">
      <div class="text-right">
        <button class="btn btn-primary btn-sm" @click="openModalList()">
          <feather-icon
            icon="PlusIcon"
            size="12"
            class="text-white align-middle"
          />
          Add Test
        </button>
      </div>
    </section>

    <b-modal
        @shown="getTestList"
        @ok="submitList"
        ok-title="Submit"
        ok-only
        centered
        title="Test List"
        size="xl"
        no-close-on-backdrop
        v-model="modalListState"
        ref="modalList"
    >
      <div class="row">
        <button
          v-for="(list, index) in listTest" v-bind:key="list.Oid"
          class="btn btn-secondary btn-sm btnSelectParent m-1 col-md-2 col-sm-3 col-12"
          @click="selectParent(list.Oid, index)"
        >
          {{ '('+list.Code+') ' + list.Name }}
        </button>
      </div>
    </b-modal>

    <b-modal
        :hide-footer="true"
        centered
        title="Input Test List"
        size="xl"
        no-close-on-backdrop
        v-model="modalInputState"
        ref="modalInput"
    >
      <form @submit.prevent="submitItem">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th width="50"></th>
                <th>Test</th>
                <th>Result</th>
                <th>Unit</th>
                <th>Reference Value</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(test, index) in selectedTest" v-bind:key="test.Oid">
                <td>
                  <button class="btn btn-danger btn-sm" type="button" @click="deleteTest(test.Oid, index)">
                    <feather-icon
                      icon="XIcon"
                      size="12"
                      class="text-white align-middle"
                    />
                  </button>
                </td>
                <td v-html="test.Name"></td>
                <td>
                  <input
                    v-if="test.ReferenceValue"
                    type="text" class="form-control"
                    autocomplete="off"
                    v-model="testResultInput[test.Oid]"
                  >
                  <span v-else></span>
                </td>
                <td>{{ test.Unit }}</td>
                <td>{{ test.ReferenceValue }}</td>
                <td>{{ test.Price }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </b-modal>

    <b-modal
        :hide-footer="true"
        centered
        title="Edit Test"
        no-close-on-backdrop
        size="lg"
        v-model="modalEditTestState"
        ref="modalInput"
    >
      <form @submit.prevent="submitEditItem" class="row">
        <div class="form-group col-12">
          <label>Test</label> <br>
          <b>{{ editTestData.Test }}</b>
        </div>
        <div class="form-group col-md-4">
          <label>Result</label>
          <input type="text" class="form-control" required autocomplete="off" v-if="editTestData.ReferenceValue" v-model="editTestData.Result">
          <br v-if="!editTestData.ReferenceValue">
          <span class="text-primary font-weight-bolder" v-if="!editTestData.ReferenceValue">N/A</span>
        </div>
        <div class="form-group col-md-4">
          <label>Unit</label> <br>
          <b>{{ editTestData.Unit }}</b>
        </div>
        <div class="form-group col-md-4">
          <label>Reference Value</label> <br>
          <b>{{ editTestData.ReferenceValue }}</b>
        </div>
        <div class="col-12 text-right">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </b-modal>
    <!-- FORM INPUT SECTION -->

    <!-- ITEMS TABLE -->
    <section class="items-section mt-2">
      <div class="table-responsive">
        <table class="table table-bordered table-hover table-striped">
          <thead class="thead-dark text-center">
            <tr>
              <th width="150"></th>
              <th>Test</th>
              <th>Result</th>
              <th>Unit</th>
              <th>Reference Value</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody v-if="items.length > 0">
            <tr v-for="item in items" v-bind:key="item.Oid">
              <td>
                <div class="btn-group" role="group">
                  <button class="btn btn-danger btn-sm btn-flat" @click="deleteItem(item.Oid)">
                    <feather-icon
                      icon="XIcon"
                      size="12"
                      class="text-white align-middle"
                    />
                  </button>
                  <button class="btn btn-warning btn-sm btn-flat" @click="editItem(item.Oid)">
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                      class="text-white align-middle"
                    />
                  </button>
                </div>
              </td>
              <td v-html="item.Test"></td>
              <td v-html="item.Result"></td>
              <td>{{ item.Unit }}</td>
              <td>{{ item.ReferenceValue }}</td>
              <td>{{ item.Price }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="6">No Test Yet</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="font-weight-bolder">
              <td colspan="5" class="text-right">Discount</td>
              <td class="d-flex justify-content-between">
                <span class="align-middle" v-if="data.DiscountValue && data.DiscountType">
                  {{ data.DiscountValue }}
                  {{ (data.DiscountType.Oid == 'Percentage') ? '%' : ''}}
                </span>
                <span class="align-middle" v-else>0</span>
                <button class="btn btn-primary btn-sm" @click="changeDiscount">
                  <feather-icon
                    icon="EditIcon"
                    size="12"
                    class="text-white align-middle"
                  />
                </button>
              </td>
            </tr>
            <tr class="font-weight-bolder">
              <td colspan="5" class="text-right">GRAND TOTAL</td>
              <td>{{ data.TotalAmount }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>
    <b-modal
        :hide-footer="true"
        centered
        title="Discount"
        no-close-on-backdrop
        v-model="modalDiscountState"
        ref="modalInput"
    >
      <form @submit.prevent="submitDiscount">
        <div class="form-group">
          <label>Discount Value</label>
          <input type="number" class="form-control" required autocomplete="off" v-model="discountData.Value">
        </div>
        <div class="form-group">
          <label>Discount Type</label>
          <select class="form-control" v-model="discountData.Type">
            <option value="null" disabled>--- Select Discount Type ---</option>
            <option value="Percentage">Percentage (%)</option>
            <option value="Value">Value</option>
          </select>
        </div>
        <div class="col-12 text-right">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </b-modal>
    <!-- END ITEMS TABLE -->
  </div>
</template>
<script>
/* eslint-disable */
import moment from 'moment'
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },

  data() {
    return {
      baseUrl: `/registration/labtrx/input/${this.$route.params.oid}`,
      data: {},
      items: [],

      listTest: [],
      selectedParent: [],
      selectedTest: [],

      modalListState: false,
      modalInputState: false,
      modalEditTestState: false,
      modalDiscountState: false,

      deleteCandidate: [], 

      testResultInput: {},

      editTestData: {},

      discountData: {},
    }
  },

  created() {
    this.load()
  },

  methods: {

    load() {
      this.loadData()
      this.loadConfig()
    },

    loadConfig() {
      this.$parent.$parent.settingConfig(this.baseUrl + '/config')
    },

    loadData() {
      this.loaderState(true)
      this.$http.get(this.baseUrl + '/data')
      .then(res => {
        res.data.data.Date = moment(res.data.data.Date).format('D MMMM Y h:mm A')
        this.data = res.data.data
        this.items = res.data.items

        this.loaderState(false)
      })
      .catch(error => {
        this.loaderState(false)
      })
    },

    getTestList() {
      this.loaderState(true)
      this.$http.get(this.baseUrl + '/get')
      .then(res => {
        this.listTest = res.data

        this.loaderState(false)
      })
      .catch(error => {
        this.loaderState(false)
      })
    },

    openModalList() {
      this.listTest = []
      this.selectedParent = []
      this.selectedTest = []
      this.modalListState = true
      this.modalInputState = false
      this.deleteCandidate = [] 
      this.testResultInput = {}
    },

    selectParent(Oid, index) {
      const el = document.getElementsByClassName('btnSelectParent')
      let state
      if(this.selectedParent.includes(Oid)) state = 'remove'
      else state = 'add'

      if(state == 'add') {
        el[index].classList.remove('btn-secondary')
        el[index].classList.add('btn-primary')
        this.selectedParent.push(Oid)
      } else {
        el[index].classList.remove('btn-primary')
        el[index].classList.add('btn-secondary')
        const arrayIndex = this.selectedParent.indexOf(Oid)
        this.selectedParent.splice(arrayIndex, 1)
      }
    },

    submitList() {
      this.loaderState(true)
      let process = 0
      this.selectedTest = []
      this.selectedParent.map((value, key) => {
        this.$http.get(this.baseUrl + '/get/' + value)
        .then(res => {
          res.data.map((value, key) => {
            this.selectedTest.push(value)
            this.$set(this.testResultInput, value.Oid, '')
          })
          process++

          if(process == this.selectedParent.length) this.loadModalInput()
        })
        .catch(error => {
          this.loaderState(false)
        })
      })
    },

    loadModalInput() {
      this.loaderState(false)
      this.modalInputState = true
    },

    deleteTest(Oid, index) {
      this.deleteCandidate = []
      this.setDeleteCandidate(Oid, index)
      
      this.deleteCandidate.reverse().forEach(idx => {
        this.selectedTest.splice(idx, 1);
      });
    },

    setDeleteCandidate(Oid, index) {
      this.deleteCandidate.push(index)
      delete this.testResultInput[Oid]
      this.selectedTest.map((value, key) => {
        if(value.Parent == Oid) this.setDeleteCandidate(value.Oid, key)
      })
    },

    submitItem() {
      this.loaderState(true)

      const data = []
      this.selectedTest.map((value, key) => {
        let tmp = {
          Oid: value.Oid,
          Parent: value.Parent,
          Result: this.testResultInput[value.Oid],
          Subtotal: value.Price
        }
        data.push(tmp)
      })

      this.$http.post(this.baseUrl + '/save', { data: data })
      .then(res => {
        this.modalInputState = false
        this.load()
        this.loaderState(false)
      })
      .catch(err => {
        this.loaderState(false)
      })
    },

    editItem(Oid) {
      this.loaderState(true)
      this.$http.get(this.baseUrl + '/' + Oid)
      .then(res => {
        this.editTestData = res.data
        this.modalEditTestState = true

        this.loaderState(false)
      })
      .catch(error => this.loaderState(false))
    },

    submitEditItem() {
      this.$http.post(this.baseUrl + '/save/' + this.editTestData.Oid, this.editTestData)
      .then(res => {
        this.modalEditTestState = false
        this.load()
        this.loaderState(false)
      })
      .catch(err => {
        this.loaderState(false)
      })
    },

    deleteItem(Oid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this test ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.loaderState(true)
          this.$http.post(this.baseUrl + '/delete/' + Oid)
          .then(res => {
            this.load()
            this.loaderState(false) 
          })
          .catch(err => this.loadState(false))
        }
      })
    },

    changeDiscount() {
      let value = parseFloat(this.data.DiscountValue.replace(',',''))
      let type = (this.data.DiscountType !== null) ? this.data.DiscountType.Oid : null
      this.discountData.Value = value
      this.discountData.Type = type
      this.modalDiscountState = true
    },

    submitDiscount() {
      this.loaderState(true)
      const params = { 
        DiscountType: this.discountData.Type, 
        DiscountValue: this.discountData.Value 
      }
      this.$http.post(`/registration/labtrx/${this.$route.params.oid}`, params)
      .then(res => {
        this.load()
        this.modalDiscountState = false
        this.loaderState(false)
      })
      .catch(err => this.loadState(false))
    }

  }
}
/* eslint-enable */
</script>
