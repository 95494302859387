<template>
    <div>
        <div class="text-right mb-2">
            <button class="btn btn-secondary" @click="$router.go(-1)">Cancel</button>
        </div>
        <accordion :accordions="fields" :data="data" ref="accordionComponent"></accordion>
        <div class="text-right mt-2" v-if="fields.length > 0">
            <button class="btn btn-primary ml-2" @click="submit('save')">Save</button>
            <button class="btn btn-success ml-2" @click="submit('done')">Done</button>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Accordion from '@/views/Admin/components/Accordion'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        Accordion
    },
    
    data() {
        return {
            fields: [],
            data: [],
        }
    },

    created() {
        this.load()
    },
    
    methods: {
        load() {
            this.fields = []
            this.data = []

            this.getFields()
        },

        getFields() {
            this.loaderState(true)
            this.$http.get(`archive/medrec/write/${this.$route.params.consultation}`)
            .then(res => {
                this.fields = res.data
                this.loaderState(false)
            })
            .catch(err => {
                this.loaderState(false)
                this.errorHandler(err.response.data)
            })
        },

        submit(type) {
            const validate = this.$refs.accordionComponent.handleState()
            if(validate) {
                this.loaderState(true)
                this.$http.post(`archive/medrec/write/${this.$route.params.consultation}?type=`+type, validate)
                .then(res => {
                    this.loaderState(false)
                    if(type == 'done') window.location = '/list/registration/consultation'
                })
                .catch(err => {
                    this.loaderState(false)
                })
            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Required Fields must be filled',
                        text: 'some fields are required, please fill in the required fields',
                        icon: 'XCircleIcon',
                        variant: 'danger',
                    },
                })
            }
        },
    }
}
/* eslint-enable */
</script>
