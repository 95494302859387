<template>
  <div>{{ errorMessage }}</div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'ErrorAlert',
  computed: {
    errorMessage : function() {
      this.alert()
    }
  },
  methods: {
    alert() {
      if (this.$store.state.error) {
        this.$swal({
          text: this.$store.state.error,
          imageUrl: require('@/assets/images/slider/erroralert.jpg'),
          imageWidth: 300,
          imageHeight: 300,
          imageAlt: 'Custom image',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
        this.$store.state.error = ""
    }
    },
  },
}
/* eslint-enable */
</script>

<style>

</style>
