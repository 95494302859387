<template>
  <div>
    <b-tabs pills horizontal>
      <b-tab v-for="(value, propertyName) in tabs" v-bind:key="value" :title="propertyName">
        <!-- Main Tabs -->
        <b-card-text v-if="propertyName === 'Main'">
          <h2 class="ml-1 mb-2"><b>Main</b></h2>
          <div class="container-fluid">
            <div class="row">
              <div v-for="field in mainTab.Fields" v-bind:key="field.fieldToSave" :class="'col-md-' + field.column + ' mb-2'">
                <label>{{ field.label }}</label> <br>
                <div v-if="field.type == 'checkbox'">
                  <b-form-checkbox
                      :value="(mainTab.Data[field.fieldToSave]) ? 'check' : 'uncheck'"
                      checked="check"
                      unchecked-value="uncheck"
                      disabled
                  >
                      {{ field.label }}
                  </b-form-checkbox>
                </div>
                <div v-else-if="field.numberFormat">
                  <span class="detailview-span"><b>{{ new Intl.NumberFormat().format(parseFloat(mainTab.Data[field.fieldToSave])) }}</b></span>
                </div>
                <div v-else>
                  <span class="detailview-span" v-if="mainTab.Data[field.fieldToSave]"><b>{{ mainTab.Data[field.fieldToSave] }}</b></span>
                  <span class="detailview-span text-primary" v-else><b>N/A</b></span>
                </div>
              </div>
            </div>
          </div>
        </b-card-text>

        <!-- Log Tabs -->
        <b-card-text v-else-if="propertyName === 'Logs'">
          <h2 class="ml-1 mb-2"><b>Logs</b></h2>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 mb-1" v-for="(value, propertyMain) in logTab" v-bind:key="value">
                <label>{{ propertyMain }}</label> <br>
                <span class="detailview-span"><b>{{ value }}</b></span>
              </div>
            </div>
          </div>
        </b-card-text>

        <!-- Detail Tabs -->
        <b-card-text v-else-if="propertyName === 'Detail'">
          <div class="row">
            <div class="col-md-3">
              <h2 class="ml-1 mb-2"><b>Detail</b></h2>
            </div>
            <div class="col-md-9 text-right">
              <button
                v-for="action in detailTab.Action"
                v-bind:key="action.name"
                class="btn btn-sm btn-outline-primary"
                @click="listAction(action)"
              >
                <feather-icon
                  :icon="action.icon"
                  class="mr-50"
                />
                {{ action.name }}
              </button>

            </div>
          </div>
          <vue-good-table :columns="detailTab.Header" :rows="detailTab.Data" styleClass="vgt-table striped bordered">
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'Action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                      <b-dropdown-item v-for="act in currentItem(props.index).Action" v-bind:key="act.name" @click="listAction(act)">
                      <feather-icon
                        :icon="act.icon"
                        class="mr-50"
                      />
                      <span>{{ act.name }}</span>
                      </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>
          </vue-good-table>
        </b-card-text>

        <!-- File Tab -->
        <b-card-text v-if="propertyName === 'File'">
          <h2 class="ml-1 mb-2"><b>File</b></h2>
          <div class="row">
            <div class="form-input-file col-md-3">
              <b-form-file ref="file" @change="handleFileUpload" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."></b-form-file>
              <b-button class="mt-1 mb-1 btn-block" @click="submitFile">Submit File</b-button>
            </div>
            <div class="col-md-9">
              <vue-good-table  :columns="fileTab.Header" :rows="fileTab.Data" styleClass="vgt-table striped bordered plainTable" :line-numbers="true">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'Action'">
                    <button
                      v-for="act in currentItem(props.index,'file').Action"
                      v-bind:key="act.name" @click="listAction(act)"
                      class="btn btn-outline-primary btn-sm mr-1">
                      <feather-icon
                        :icon="act.icon"
                        class="mr-50"
                      />
                      {{ act.name }}
                    </button>
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
/* eslint-disable */
import { 
  BCardText, BTabs, BTab, BFormGroup, BForm, BFormCheckbox, BDropdown, BDropdownItem, BRow, BCol, BButton, BTable, BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BFormFile,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BTable,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BCardText,
    BTabs,
    BTab,
  },
  data() {
    return {
        tabs: {},
        mainTab: {},
        detailTab: {},
        logTab: {},
        fileTab: {},
    }
  },
  created() {
    this.reset()
    this.loadData()
  },
  methods: {
    reset() {
      this.tabs = {}
      this.mainTab = {}
      this.detailTab = {}
      this.logTab = {}
      this.fileTab = {}
    },

    loadData() {
      this.$http.get(`${this.$route.params.module}/${this.$route.params.submodule}/detail/${this.$route.params.oid}`)
      .then((res) => {
        this.detailRow = []
        this.tabs = res.data
        if(typeof res.data.Main !== 'undefined') this.loadMain(res.data.Main)
        if(typeof res.data.Logs !== 'undefined') this.loadLogs(res.data.Logs)
        if(typeof res.data.Detail !== 'undefined') this.loadDetail(res.data.Detail)
        if(typeof res.data.File !== 'undefined') this.loadFile(res.data.File)
      })
    },

    listAction(action) {
      this.$parent.$parent.action(action)
    },

    // Start Main Tab
    loadMain(main) {
      this.mainTab.Fields = main.Field
      this.mainTab.Data = main.Data
    },
    // End Main Tab

    // Start Logs Tab
    loadLogs(logs) {
      this.logTab = logs
    },
    // End Logs Tab

    // Start Detail Tab
    loadDetail(detail) {
      this.detailTab.Action = detail.Action
      this.setColumnHeader(detail.Table)
      this.detailTab.Data = detail.Data
    },

    setColumnHeader(columns) {
      const col = [
        {
          label: '',
          field: 'Action',
          width: '50px',
          sortable: false
        }
      ]
      columns.map((value,key) => {
        value.thClass = 'text-left'
        value.tdClass = 'text-left'
        if(value.type == 'decimal') value.formatFn = this.setNumberFormat
        col.push(value)
      })

      this.detailTab.Header = col
    },

    setNumberFormat: function(value) {
      return new Intl.NumberFormat().format(parseFloat(value))
    },

    currentItem(index, table = 'detail') {
      if(table == 'detail') return this.detailTab.Data[index]
      else if(table == 'file') return this.fileTab.Data[index]
    },
    // End Detail Tab

    // Start File Tab
    loadFile(file) {
      this.fileTab.Post = file.Post
      this.fileTab.Header = [
        { label: 'File', field: 'Name' },
        { label: 'Action', field: 'Action', width: '300px' }
      ]
      this.fileTab.Data = []
      
      file.Data.map((value, key) => {
        const action = [
          {
            name: "Open",
            type: "OpenLink",
            icon: "EyeIcon",
            newTab: true,
            link: value.Url
          },
          {
            name: "Delete",
            type: "Confirmation",
            icon: "TrashIcon",
            newTab: true,
            post: file.Delete.replace('{Oid}',value.Oid),
            afterRequest: 'init'
          }
        ]

        this.fileTab.Data.push({
          Name: value.Name,
          Url: value.Url,
          Action: action
        })
      })
    },
    
    handleFileUpload(event) {
      this.fileTab.formData = event.target.files[0]
    },

    submitFile() {
      let formData = new FormData()
      formData.append('file', this.fileTab.formData)
      this.$http.post(this.fileTab.Post, formData) 
        .then((res) => {
          res.data
          this.loadData()
        })
    }
    // End File Tab
  }
}
/* eslint-enable */
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
table.vgt-table {
  width: 100%;
  & td {
    padding: .75em .75em 0 .75em;
    cursor: pointer;
  }
}
table.plainTable td{
  padding: .75em !important;
}
.detailview-span {
    white-space: pre-line;
}
</style>
