<template>
    <div>
        <section class="presearch mb-1">
            <div class="row">
                <div class="col-md-10 col-12">
                    <presearch :fields="presearch" :noLabel="false" :automateColumn="'true'" ref="fieldPresearch" />
                </div>
                <div class="col-md-2 col-12 text-right">
                    <br>
                    <button class="btn btn-secondary btn-icon" @click="searchHandler">
                        <feather-icon
                            icon="SearchIcon"
                            size="16"
                            class="text-white align-middle"
                        />
                    </button>
                </div>
            </div>
        </section>
        <section class="tickets">
            <b-list-group>
                <b-list-group-item
                    v-for="row in list"
                    v-bind:key="row.Oid"
                    class="d-flex justify-content-between align-items-center"
                >
                    <div class="left-side">
                        <h6>{{ row.Code }}</h6>
                        <h4>{{ row.Subject }}</h4>
                        <span class="text-muted">
                            {{ row.Requester }} [{{ row.Client }}] | <b>{{ row.Application }}</b>
                        </span>
                    </div>
                    <div class="right-side">
                        <b-badge pill :variant="variants[row.Type]" class="mr-1">{{ row.Type }}</b-badge>
                        <b-badge pill :variant="variants[row.Priority]">{{ row.Priority }}</b-badge>
                        <b-dropdown
                            variant="secondary"
                            size="sm"
                            toggle-class="text-decoration-none"
                            no-caret
                            class="dropdown-action ml-2"
                            right
                        >
                            <template v-slot:button-content>
                                <feather-icon
                                    icon="ChevronDownIcon"
                                    size="16"
                                    class="text-white align-middle"
                                />
                            </template>
                            <b-dropdown-item
                                v-for="action in row.Action"
                                v-bind:key="action.name"
                                @click="listAction(action)"
                            >
                                <feather-icon
                                    :icon="action.icon"
                                    class="mr-50"
                                />
                                <span>{{ action.name }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </b-list-group-item>
            </b-list-group>
        </section>
    </div>
</template>
<script>
/* eslint-disable */
import { BListGroup, BListGroupItem, BBadge, BButton, BDropdown, BDropdownItem } from 'bootstrap-vue'
import Presearch from '@/views/Admin/components/Field.vue'

export default {
    components: {
        BListGroup,
        BListGroupItem,
        BBadge,
        BButton,
        BDropdown, 
        BDropdownItem,
        Presearch,
    },

    data() {
        return {
            list: [],
            presearch: [],
            variants: {
                HIGH: 'danger',
                MEDIUM: 'warning',
                LOW: 'info',
                BUG: 'primary',
                REQUEST: 'dark'
            },
            serverParams: {}
        }
    },
    
    created() {
        this.load()
    },

    methods: {
        load(type = null) {
            this.$http.get(this.portalApi() + '/support/ticket', { params: this.serverParams })
            .then(res => {
                if(type == 'presearch') this.presearch = res.data.presearch
                else if(type == 'list') this.list = res.data.data
                else {
                    this.presearch = res.data.presearch
                    this.list = res.data.data
                }
            })
        },
        
        listAction(data) {
            this.$parent.$parent.action(data)
        },

        searchHandler() {
            let presearchValue = this.$refs.fieldPresearch.handleState()
            if(presearchValue) this.presearch.map((value, key) => {
                if(typeof presearchValue[value.fieldToSave] !== 'undefined' && presearchValue[value.fieldToSave] !== '') this.$set(this.serverParams, value.fieldToSave, presearchValue[value.fieldToSave])
                else delete this.serverParams[value.fieldToSave]
            })
            this.load('list')
        },
    }
}
</script>
