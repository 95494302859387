<template>
  <div id="printable">
    <b-row>
      <!-- INVOICE HEADER -->
      <section class="company col-md-6 col-12">
        <section class="logo row">
          <div class="col-md-6 col-12 d-flex justify-content-start">
            <img :src="company.Logo" height="50">
            <span class="text ml-2 my-auto">{{ company.Name }}</span>
          </div>
        </section>
        <section class="address mt-1">
          {{ company.Address }}
        </section>
      </section>

      <section class="invoice-identity col-md-6 col-12">
        <div class="invoice-number">
          {{ invoiceIdentity.Number }}
        </div>
        <div class="invoice-date">
          <div v-for="date in invoiceIdentity.Date" v-bind:key="date.key"><b>{{ date.key }}</b> : {{ date.value }}</div>
        </div>
      </section>
      <!-- END INVOICE HEADER -->
    </b-row>
    <hr class="divider">
    <b-row>
      <!-- INVOICE INFO --->
      <section class="invoice-info col-md-7 col-12" v-if="(typeof invoiceInfo.Left !== 'undefined' && invoiceInfo.Left !== null)">
        <h4 class="invoice-info-title">{{ invoiceInfo.Left.Title }}</h4>
        <table class="invoice-info-value">
          <tr v-for="info in invoiceInfo.Left.Value" v-bind:key="info.Key">
            <th>{{ info.Key }} </th>
            <td>: {{ info.Value }}</td>
          </tr>
        </table>
      </section>

      <section class="invoice-info col-md-5 col-12" v-if="(typeof invoiceInfo.Right !== 'undefined' && invoiceInfo.Right !== null)">
        <h4 class="invoice-info-title">{{ invoiceInfo.Right.Title }}</h4>
        <table class="invoice-info-value">
          <tr v-for="info in invoiceInfo.Right.Value" v-bind:key="info.Key">
            <th>{{ info.Key }} </th>
            <td>: {{ info.Value }}</td>
          </tr>
        </table>
      </section>
      <!-- END INVOICE INFO --->
    </b-row>
    <b-row class="mt-5">
      <!-- INVOICE ITEM -->
      <section class="item col-12">
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          styleClass="table table-bordered table-striped"
        />
      </section>
      <!-- END INVOICE ITEM -->
    </b-row>
    <b-row class="mt-5">
      <!-- INVOICE FOOTER -->
      <section class="invoice-remark col-6">
        <b>Remark</b> : {{ invoiceIdentity.Remarks }}
      </section>
      <div class="col-1"></div>
      <section class="invoice-totals col-5">
        <table class="table">
          <tr v-for="total in invoiceTotals.PreTotal" v-bind:key="total.Key">
            <td>{{ total.Key }}</td>
            <td>{{ total.Value }}</td>
          </tr>
          <tr class="invoice-grandtotal">
            <th>GRAND TOTAL</th>
            <th>{{ invoiceTotals.GrandTotal }}</th>
          </tr>
        </table>
      </section>
      <!-- END INVOICE FOOTER -->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BRow, BCol, BCard, BCardBody, BCardText, BButton, BLink
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BLink,
    VueGoodTable,
  },
  
  data() {
    return {
      company: {},
      invoiceIdentity: {},
      invoiceInfo: {},
      invoiceTotals: {},
      columns: [],
      rows: []
    }
  },

  created() {
    this.load()
  },

  methods: {
    load() {
      this.loaderState(true)
      this.$http.get(`${this.$route.params.module}/${this.$route.params.submodule}/invoice/${this.$route.params.oid}`)
        .then(res => {
          this.setCompany(res.data.Company)
          this.setIdentity(res.data.Invoice)
          this.setInfo(res.data.Information)
          this.setTable(res.data.Items)
          this.setTotals(res.data.Invoice)

          this.loaderState(false)
        })
        .catch(err => {
          this.loaderState(false)
        })
    },

    // SET TABLE
    setTable(data) {
      this.setColumnHeader(data.Table)
      this.rows = data.List
      this.resizeTable()
    },

    resizeTable() {
      document.getElementsByClassName('vgt-responsive')[0].classList.add('vgt-responsive-for-invoice')
      document.getElementsByClassName('vgt-responsive-for-invoice')[0].classList.remove('vgt-responsive')
    },

    setColumnHeader(columns) {
      const col = []
      columns.map((value,key) => {
        value.thClass = 'text-left'
        value.tdClass = 'text-left'
        if(value.type == 'decimal') value.formatFn = this.setNumberFormat
        col.push(value)
      })

      this.columns = col
    },

    setNumberFormat: function(value) {
      let formatted = new Intl.NumberFormat().format(parseFloat(value))
      if(formatted == 'NaN') return 0
      else return formatted
    },
    // END SET TABLE

    // SET IDENTITY
    setIdentity(data) {
      this.$set(this.invoiceIdentity, 'Number', data.Number)
      this.$set(this.invoiceIdentity, 'Remarks', data.Remarks)
      this.$set(this.invoiceIdentity, 'Date', [])
      Object.keys(data.Date).map((value, key) => {
        this.invoiceIdentity.Date.push({ key: value, value: data.Date[value] })
      })
    },
    // END SET IDENTITY

    // SET TOTALS
    setTotals(data) {
      this.invoiceTotals = data.Total
    },
    // END SET TOTALS

    // SET INFO
    setInfo(data) {
      this.invoiceInfo = data
    },
    // END SET INFO

    // SET COMPANY
    setCompany(data) {
      this.company = data
    },
    // END SET COMPANY

  },
}
/* eslint-enable */
</script>
<style scoped lang="scss">
  .company .logo .text {
    font-size: 15pt;
    font-weight: bold;
  }
  .company .address {
    white-space: pre-wrap;
  }
  .invoice-identity {
    text-align: right;
  }
  .invoice-identity .invoice-number {
    font-weight: bold;
    font-size: 20pt;
  }
  hr.divider {
    margin-top: 5px;
    margin-bottom: 20px;
  }
  .invoice-grandtotal {
    font-size: 15pt;
  }
</style>
<style>
  .vgt-responsive-for-invoice {
    width: 100%;
    overflow-x: auto;
    position: relative;
  }
</style>
