<template>
  <b-modal
    @shown="loadForm"
    @hidden="handleClose"
    ok-only
    ok-title="Close"
    centered
    :title="data.modal.name"
    size="lg"
    no-close-on-backdrop
    v-model="show"
  >
    <form>
      <div class="row">
        <b-form-group
          v-for="field in form"
          v-bind:key="field.fieldToSave"
          :class="'col-md-'+field.column"
        >
          <label v-if="field.type !== 'labeltext'">{{ field.label }} :</label>
          <label v-else class="text-primary" style="font-weight: 900; font-size: 15pt; margin-top: 35px; margin-bottom: 0px !important; padding: 0;">
            {{ field.label }}
            <hr style="margin-top:0 !important; margin-bottom: 0 !important;">
          </label>
          <br>
          <b-form-checkbox
            v-if="field.type === 'checkbox'"
            v-model="fieldData[field.fieldToSave]"
            disabled
            value="check"
            unchecked-value="uncheck"
          >
            {{ field.label }}
          </b-form-checkbox>
          <span class="openform-span" v-else-if="field.type === 'comboautocomplete' || field.type == 'combosource'"><b>{{ (fieldData[field.fieldToSave] !== null) ? fieldData[field.fieldToSave].Name : '' }}</b></span>
          <span class="openform-span" v-else-if="field.numberFormat === true">
            <b>{{ (new Intl.NumberFormat().format(parseFloat(fieldData[field.fieldToSave])) !== 'NaN') ? new Intl.NumberFormat().format(parseFloat(fieldData[field.fieldToSave])) : 0 }}</b>
          </span>
          <span class="openform-span" :name="field.fieldToSave" v-else>
            <div v-if="field.type !== 'labeltext'">
              <b v-if="fieldData[field.fieldToSave]">{{ (fieldData[field.fieldToSave]) }}</b>
              <b v-else style="color: #7367f0">N/A</b>
            </div>
          </span>
        </b-form-group>
      </div>
    </form>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { BModal, BCardText, BFormGroup, BFormInput, BFormTextarea, BFormCheckbox, BFormDatepicker, BFormTimepicker } from 'bootstrap-vue'

export default {
  name: 'OpenForm',
  props: ['data', 'show'],
  data() {
    return {
      form: [],
      fieldData: {},
    }
  },
  components: {
    BCardText,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
  },
  methods: {
    reset() {
      this.form = []
      this.fieldData = {}
    },

    loadForm() {
      this.reset()
      
      this.form = this.data.modal.form
      this.fieldData = this.data.data
      this.form.map((value,key) => {
        if(value.type == 'checkbox') {
          if(this.fieldData[value.fieldToSave] === 1) this.fieldData[value.fieldToSave] = 'check'
          else this.fieldData[value.fieldToSave] = 'uncheck'
        }
      })
    },

    handleClose() {
      this.reset()

      this.$parent.openFormShow = false
    }
  },
}
/* eslint-enable */
</script>

<style>
  .openform-span {
    white-space: pre-line;
  }
</style>
