<template>
  <div>
    <!-- HEADER -->
    <section class="header row">
      <div class="header-left col-md-8 col-12" v-if="(typeof information.Left !== 'undefined')">
        <h4 class="header-title">{{ information.Left.Title }}</h4>
        <table class="header-value">
          <tr v-for="info in information.Left.Value" v-bind:key="info.Key">
            <th width="100">{{ info.Key }}</th>
            <td>: {{ info.Value }}</td>
          </tr>
        </table>
      </div>

      <div class="header-right col-md-4 col-12" v-if="(typeof information.Right !== 'undefined')">
        <h4 class="header-title">{{ information.Right.Title }}</h4>
        <table class="header-value">
          <tr v-for="info in information.Right.Value" v-bind:key="info.Key">
            <th width="100">{{ info.Key }}</th>
            <td>: {{ info.Value }}</td>
          </tr>
        </table>
      </div>
    </section>
    <!-- END HEADER -->

    <!-- TABLE -->
    <section class="table-cart mt-3">
      <div class="text-right mb-2">
        <button class="btn btn-success btn-sm" @click="addItem()">
          <feather-icon
            icon="PlusIcon"
            size="12"
            class="text-white align-middle"
          />
          Add Item
        </button>
      </div>
      <table class="table table-bordered table-hover">
        <thead class="thead-dark text-center">
          <tr>
            <th></th>
            <th v-for="header in tableColumn" v-bind:key="header.field">{{ header.label }}</th>
          </tr>
        </thead>
          <tr v-for="item in items" v-bind:key="item.Oid">
            <td class="text-center" width="150">
              <div class="btn-group" role="group">
                <button class="btn btn-danger btn-sm btn-flat" @click="removeItem(item.Oid)">
                  <feather-icon
                    icon="XIcon"
                    size="12"
                    class="text-white align-middle"
                  />
                </button>
                <button class="btn btn-warning btn-sm btn-flat" @click="editItem(item.Oid)">
                  <feather-icon
                    icon="EditIcon"
                    size="12"
                    class="text-white align-middle"
                  />
                </button>
              </div>
            </td>
            <td v-for="header in tableColumn" v-bind:key="header.field">{{ item[header.field] }}</td>
          </tr>
        <tbody>
        </tbody>
      </table>
    </section>
    <!-- END TABLE -->

    <!-- PRETOTAL -->
    <section class="pretotal row mt-2">
      <div class="col-md-7 col-12"></div>
      <div class="col-md-5 col-12">
        <table class="table">
          <tr v-for="total in preTotal" v-bind:key="total.Key">
            <th>{{ total.Key }}</th>
            <td>{{ data[total.Key] }}</td>
            <td width="100">
              <button class="btn btn-primary btn-sm" @click="changePreTotal(total)">
                <feather-icon
                  icon="EditIcon"
                  size="12"
                  class="text-white align-middle"
                />
              </button>
            </td>
          </tr>
          <tr>
            <th width="230"><h3><b>GRAND TOTAL</b></h3></th>
            <th colspan="2"><h3><b>{{ data.GrandTotal }}</b></h3></th>
          </tr>
        </table>
      </div>
    </section>
    <!-- END PRETOTAL -->
  </div>
</template>
<script>
/* eslint-disable */

export default {
  components: {
    
  },

  data() {
    return {
      information: {},
      tableColumn: [],
      preTotal: [],
      data: {},
      items: [],
      fields: [],
    }
  },

  created() {
    this.loaderState(true)
    this.getCartFormat()
  },

  methods: {
    getCartFormat() {
      this.$http.get(`${this.$route.params.module}/${this.$route.params.submodule}/cart/format/${this.$route.params.oid}`)
        .then(data => {
          this.information = data.data.Information
          this.tableColumn = data.data.TableHeader
          this.fields = data.data.Form
          this.preTotal = data.data.PreTotal

          this.loadData()
        })
        .catch(error => {
          this.loaderState(false)
        })
    },

    loadData() {
      this.$http.get(`${this.$route.params.module}/${this.$route.params.submodule}/cart/${this.$route.params.oid}`)
        .then(data => {
          this.data = data.data.Transaction
          this.items = data.data.Items

          this.loaderState(false)
        })
        .catch(error => {
          this.loaderState(false)
        })
    },

    addItem() {
      let actionFormat = {
        form: this.fields,
        name: 'Add Item',
        post: `${this.$route.params.module}/${this.$route.params.submodule}/cart/${this.$route.params.oid}`,
        size: 'lg',
        type: 'GlobalForm',
        afterRequest: 'init'
      }
      this.$parent.$parent.action(actionFormat)
    },

    removeItem(item) {
      let actionFormat = {
        name: 'Remove Item',
        post: `${this.$route.params.module}/${this.$route.params.submodule}/cart/${this.$route.params.oid}/${item}/remove`,
        type: 'Confirmation',
        afterRequest: 'init'
      }
      this.$parent.$parent.action(actionFormat)
    },

    editItem(item) {
      let actionFormat = {
        form: this.fields,
        name: 'Edit Item',
        post: `${this.$route.params.module}/${this.$route.params.submodule}/cart/${this.$route.params.oid}/${item}`,
        get: `${this.$route.params.module}/${this.$route.params.submodule}/cart/${this.$route.params.oid}/${item}`,
        size: 'lg',
        type: 'GlobalForm',
        afterRequest: 'init'
      }
      this.$parent.$parent.action(actionFormat)
    },

    changePreTotal(field) {
      let actionFormat = {
        form: [field.Field],
        name: 'Change ' + field.Key,
        post: `${this.$route.params.module}/${this.$route.params.submodule}/cart/${this.$route.params.oid}`,
        type: 'GlobalForm',
        afterRequest: 'init'
      }
      this.$parent.$parent.action(actionFormat)
    }
  }
}
/* eslint-enable */
</script>
<style scoped>
  .header table.header-value {
    width: 100%;
  }
</style>
