<template>
  <div>
    <global-tabs :tabs="tabs" :serverParams="serverParams" :loadData="loadData"  />
    <div class="row">
      <div class="col-md-9">
        <presearch v-if="enablePresearch === true" :fields="presearch" :noLabel="false" :data="undefined" :automateColumn="'true'" ref="fieldPresearch" />
      </div>
      <div class="col-md-3 mb-2">
        <div class="custom-search">
          <label>Search</label>
          <b-input-group>
            <b-form-input
              placeholder="Search"
              type="text"
              class="d-inline-block"
              autocomplete="off"
              @change="searchChangeHandler"
            />
            <b-input-group-append>
              <b-button variant="secondary" @click="searchHandler">
                <feather-icon
                  icon="SearchIcon"
                  size="12"
                  class="text-white align-middle"
                />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </div>
    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="rows"
      :totalRows="totalRecords"
      :line-numbers="true"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      styleClass="vgt-table striped">
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'Action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="dropdown-action"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-for="act in currentItem(props.index).Action" v-bind:key="act.name" @click="listAction(act)">
                <feather-icon
                  :icon="act.icon"
                  class="mr-50"
                />
                <span>{{ act.name }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="paginationOptions"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
/* eslint-disable */
import { VueGoodTable } from 'vue-good-table'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import Presearch from '@/views/Admin/components/Field.vue'
import GlobalTabs from '@/views/Admin/components/Tabs.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    Presearch,
    GlobalTabs,
  },
  data() {
    return {
      tabs: [],
      presearch: [],
      enablePresearch: false,
      searchField: '',
      isLoading: true,
      pageLength: 10,
      totalRecords: 0,
      columns: [],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      },
      paginationOptions: ['3','5','10'],
    }
  },
  watch: {
    '$route': function(to, from) {
      this.loaderState(true)
      this.load()
    },
  },
  created() {
    this.load()
    setTimeout(() => {
      if(this.tabs == null || this.tabs.length === 0) this.loadData()
    },1000)
  },
  methods: {
    load() {
      this.columns = []
      this.rows = []
      this.tabs = []
      this.presearch = []
      this.enablePresearch = false,
      this.searchField = '',
      this.activeTabs = ''
      this.globalFormShow = false
      this.serverParams = {
        columnFilters: {},
        sort: [{ field: '', type: '' }],
        page: 1,
        perPage: 10,
      }
      this.loadItems()
    },

    searchHandler() {
      let presearchValue
      if(this.enablePresearch === true) {
        presearchValue = this.$refs.fieldPresearch.handleState()
        if(presearchValue) this.presearch.map((value, key) => {
          if(typeof presearchValue[value.fieldToSave] !== 'undefined' && presearchValue[value.fieldToSave] !== '') this.$set(this.serverParams, value.fieldToSave, presearchValue[value.fieldToSave])
          else delete this.serverParams[value.fieldToSave]
        })
      }

      if(typeof this.searchField !== 'undefined' && this.searchField !== '') this.$set(this.serverParams, 'searchTerm', this.searchField)
      else delete this.serverParams.searchTerm

      if(this.enablePresearch === true) {
        if(presearchValue) this.loadData()
      } else this.loadData()
    },

    searchChangeHandler(val) {
      if(val === '' || typeof val === 'undefined') this.searchField = undefined
      else this.searchField = val
    },

    listAction(action) {
      this.$parent.$parent.action(action)
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadData()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadData()
    },

    onSortChange(params) {
      params = JSON.parse(JSON.stringify(params))
      let sortCol = []
      params.map((value, key) => {
        let tmp = {
          type: value.type,
          field: value.field
        }
        sortCol.push(tmp)
      })
      this.updateParams({
        sort: sortCol,
      })
      this.loadData()
    },
    
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadData()
    },

    currentItem(index) {
      return this.rows[index]
    },

    loadItems() {
      this.$http.get(`${this.$route.params.module}/${this.$route.params.submodule}/list`,{ params: this.serverParams }).then(res => {
        this.totalRecords = res.data.Pagination.totalRecords
        
        if(res.data.Presearch !== null)  {
          this.presearch = res.data.Presearch
          this.enablePresearch = true
        }
        this.tabs = res.data.Tabs
        this.setColumnHeader(res.data.Table)

        const ini = this
        setTimeout(function(){
          ini.searchHandler()
        },800)
      })
    },

    loadData() {
      this.$http.get(`${this.$route.params.module}/${this.$route.params.submodule}/list`,{ params: this.serverParams }).then(res => {
        this.totalRecords = res.data.Pagination.totalRecords

        if(this.totalRecords > 0) {
          this.loadRows(res.data.List)
          this.serverParams.perPage = res.data.Pagination.perPage
          this.pageLength = res.data.Pagination.perPage
          if(!this.paginationOptions.includes(res.data.Pagination.perPage)) this.paginationOptions = [res.data.Pagination.perPage]
        } else this.loadRows([])

        this.loaderState(false)
      })
      .catch(err => this.loaderState(false))
    },

    setColumnHeader(columns) {
      const col = [
        {
          label: '',
          field: 'Action',
          width: '50px',
          sortable: false
        }
      ]
      columns.map((value,key) => {
        value.thClass = 'text-left'
        value.tdClass = 'text-left'
        if(value.type == 'decimal') value.formatFn = this.setNumberFormat
        col.push(value)
      })

      this.columns = col
    },

    loadRows(list) {
      this.rows = list
    },

    setNumberFormat: function(value) {
      return new Intl.NumberFormat().format(parseFloat(value))
    }

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.vgt-responsive {
  min-height: 500px;
}
table.vgt-table {
  font-size: 15px;
  & td {
    padding: .75em .75em 0 .75em;
  }
}
.dropdown-action .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
