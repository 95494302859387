<template>
    <div class="row">
        <div class="col-12" v-for="field in formattedFields" v-bind:key="field.id">
            <div class="row">
                <field class="col-10" :fields="field.fields" :data="field.data" :ref="'itemFieldComponentOf'+field.id"/>
                <div class="col-2">
                    <br>
                    <button class="btn btn-success btn-sm mr-1" @click="plus(null)">
                        <feather-icon
                        icon="PlusIcon"
                        size="16"
                        />
                    </button>
                    <button class="btn btn-danger btn-sm mr-1 btnRemove" @click="minus(field.id)">
                        <feather-icon
                        icon="XIcon"
                        size="16"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Field from '@/views/Admin/components/Field.vue'

export default {
    name: 'MultipleInput',
    props: ['fields','data'],
    components: {
        Field,
    },

    data() {
        return {
            codes: [],
            formattedFields: []
        }
    },

    created() {
        this.load()
    },

    watch: {
        formattedFields: function() {
            const el = document.getElementsByClassName('btnRemove')
            if(this.formattedFields.length <= 1) for(let i = 0; i < el.length; i++) el[i].setAttribute('disabled','disabled')
            else for(let i = 0; i < el.length; i++) el[i].removeAttribute('disabled')
        }
    },

    methods: {
        load() {
            this.formattedFields = []
            this.codes = []
            if(typeof this.data !== 'undefined') {
                this.data.map((value, key) => {
                    this.plus(value)
                })
            } else this.plus()
        },

        plus(data = null) {
            const id = this.newId()
            const fields = []
            this.fields.map((value,key) => {
                let keys = Object.keys(value)
                let tmp = {}
                keys.map((val,ky) => {
                    if(val === 'fieldToSave') tmp[val] = value[val] + id
                    else tmp[val] = value[val]
                })
                fields.push(tmp)
            })
            this.codes.push(id)
            let format = {
                id: id,
                fields: fields
            }
            if(data) {
                const newData = {}
                Object.keys(data).map((value, key) => {
                    newData[value + id] = data[value]
                })
                format.data = newData
            }
            this.formattedFields.push(format)
        },

        minus(id) {
            this.formattedFields.map((value, key) => {
                if(value.id === id) {
                    this.formattedFields.splice(key,1)
                    const index = this.codes.indexOf(id)
                    this.codes.splice(index, 1)
                }
            })
        },

        newId() {
            let code = Math.floor(Math.random()*(999-100+1)+100)
            return code.toString()
        },

        handleState() {
            let validate = true
            let formData = {codesOfMultipleInput: this.codes}
            this.formattedFields.map((value, key) => {
                let keyComponent = 'itemFieldComponentOf'+value.id
                const check = this.$refs[keyComponent][0].handleState()
                if(!check) validate = false
                else Object.assign(formData, check)
            })
            return (validate) ? formData : validate
        },
    }
}
/* eslint-enable */
</script>
